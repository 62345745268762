<template>
  <div class="columns is-centered is-marginless" style="height: 100%">
    <div
      class="column is-6"
      style="height: 100%"
    >
      <div class="column is-narrow">
        <h1 class="is-size-4 has-text-weight-medium">
          Create Account
        </h1>
      </div>
      <div class="column is-narrow">
        <form @submit.prevent="create" class="mb-5">
          <b-field
            custom-class=""
            :type="error.email ? 'is-danger' : ''"
            :message="error.email"
          >
            <b-input
              placeholder="Email"
              type="email"
              custom-class="custum-input"
              v-model="user.email">
            </b-input>
          </b-field>
          <b-field
            custom-class=""
            :type="error.firstName ? 'is-danger' : ''"
            :message="error.firstName"
          >
            <b-input
              placeholder="FirstName"
              custom-class="custum-input"
              v-model="user.firstName">
            </b-input>
          </b-field>
          <b-field
            custom-class=""
            :type="error.lastName ? 'is-danger' : ''"
            :message="error.lastName"
          >
            <b-input
              placeholder="LastName"
              custom-class="custum-input"
              v-model="user.lastName">
            </b-input>
          </b-field>
          <b-field
            custom-class=""
            :type="error.mobileNumber ? 'is-danger' : ''"
            :message="error.mobileNumber"
          >
            <b-input
              placeholder="MobileNumber"
              custom-class="custum-input"
              v-model="user.mobileNumber">
            </b-input>
          </b-field>
          <b-field>
            <b-select
              placeholder="Select a profil"
              v-model="user.profil"
              expanded>
                <option
                  v-for="profil in profils"
                  :value="profil.value"
                  :key="profil.value">
                  {{ profil.label }}
                </option>
            </b-select>
          </b-field>
        </form>
        <div class="columns is-centered">
          <div class="column">
            <b-button
              expanded
              @click="$router.go(-1)"
              class="has-text-weight-bold has-text-primary"
            >
              Go back
            </b-button>
          </div>
          <div class="column">
            <b-button
              type="is-primary"
              expanded
              @click="create"
              class="has-text-weight-bold"
            >
              Create
            </b-button>
          </div>
        </div>
      </div>
      <div class="column"></div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { validation } from '@/services/validation';
import { CreateUser } from '@/api/user';
import { PROFILS, PATIENT } from '@/config/user';

export default {
  metaInfo: {
    title: 'Create user',
  },
  components: {},
  data() {
    return {
      user: {
        email: '',
        firstName: '',
        lastName: '',
        mobileNumber: '',
        profil: PATIENT,
      },
      error: {
        email: '',
        firstName: '',
        lastName: '',
        mobileNumber: '',
      },
      profils: PROFILS,
    };
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapMutations({}),
    async create() {
      if (!validation(this.user, this.error, 2000)) return '';
      const loadingComponent = this.$buefy.loading.open();
      try {
        await CreateUser(this.user, uuidv4());
        loadingComponent.close();
        this.$buefy.toast.open({
          message: 'User successfully created',
          type: 'is-success',
          position: 'is-bottom',
        });
        this.$router.push({ name: 'Users' });
        return '';
      } catch (error) {
        loadingComponent.close();
        this.showError(error);
        return '';
      }
    },
  },
  mounted() {
  },
};
</script>
